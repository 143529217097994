<template>
	<w-layout>
		<w-flex>
			<a :href="`https://gitlab.com/welyb/front-end/mobile-app/-/tree/${application.branchname}`" target="_blank">
				<v-img src="/img/gitlab-logo-gray-rgb.png" contain max-height="48px" max-width="100px" />
			</a>
		</w-flex>
	</w-layout>
</template>
<script>
export default {
	name: 'MobileAppGitlabBranchLink',
	props: {
		value: {
			required: true,
			type: Object
		}
	},
	computed: {
		application: {
			get: function () {
				return this.value
			},
			set: function (val) {
				this.$emit('input', val)
			}
		}
	}
}
</script>
